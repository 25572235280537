import { default as admin02XFd05CPxMeta } from "/opt/buildhome/repo/pages/admin.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as liquidationsBmZeTS4MEMMeta } from "/opt/buildhome/repo/pages/liquidations.vue?macro=true";
import { default as indexSsDz5cvqPdMeta } from "/opt/buildhome/repo/pages/payment/[id]/index.vue?macro=true";
import { default as refundsPHoWRM86ySMeta } from "/opt/buildhome/repo/pages/payment/[id]/refunds.vue?macro=true";
import { default as paymentstKJ0F33b5mMeta } from "/opt/buildhome/repo/pages/payments.vue?macro=true";
import { default as refundsX2YyM7ParKMeta } from "/opt/buildhome/repo/pages/refunds.vue?macro=true";
import { default as _91token_93S63u4dmQQAMeta } from "/opt/buildhome/repo/pages/verifyEmail/[token].vue?macro=true";
export default [
  {
    name: admin02XFd05CPxMeta?.name ?? "admin",
    path: admin02XFd05CPxMeta?.path ?? "/admin",
    meta: admin02XFd05CPxMeta || {},
    alias: admin02XFd05CPxMeta?.alias || [],
    redirect: admin02XFd05CPxMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: liquidationsBmZeTS4MEMMeta?.name ?? "liquidations",
    path: liquidationsBmZeTS4MEMMeta?.path ?? "/liquidations",
    meta: liquidationsBmZeTS4MEMMeta || {},
    alias: liquidationsBmZeTS4MEMMeta?.alias || [],
    redirect: liquidationsBmZeTS4MEMMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/liquidations.vue").then(m => m.default || m)
  },
  {
    name: indexSsDz5cvqPdMeta?.name ?? "payment-id",
    path: indexSsDz5cvqPdMeta?.path ?? "/payment/:id()",
    meta: indexSsDz5cvqPdMeta || {},
    alias: indexSsDz5cvqPdMeta?.alias || [],
    redirect: indexSsDz5cvqPdMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/payment/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: refundsPHoWRM86ySMeta?.name ?? "payment-id-refunds",
    path: refundsPHoWRM86ySMeta?.path ?? "/payment/:id()/refunds",
    meta: refundsPHoWRM86ySMeta || {},
    alias: refundsPHoWRM86ySMeta?.alias || [],
    redirect: refundsPHoWRM86ySMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/payment/[id]/refunds.vue").then(m => m.default || m)
  },
  {
    name: paymentstKJ0F33b5mMeta?.name ?? "payments",
    path: paymentstKJ0F33b5mMeta?.path ?? "/payments",
    meta: paymentstKJ0F33b5mMeta || {},
    alias: paymentstKJ0F33b5mMeta?.alias || [],
    redirect: paymentstKJ0F33b5mMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/payments.vue").then(m => m.default || m)
  },
  {
    name: refundsX2YyM7ParKMeta?.name ?? "refunds",
    path: refundsX2YyM7ParKMeta?.path ?? "/refunds",
    meta: refundsX2YyM7ParKMeta || {},
    alias: refundsX2YyM7ParKMeta?.alias || [],
    redirect: refundsX2YyM7ParKMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/refunds.vue").then(m => m.default || m)
  },
  {
    name: _91token_93S63u4dmQQAMeta?.name ?? "verifyEmail-token",
    path: _91token_93S63u4dmQQAMeta?.path ?? "/verifyEmail/:token()",
    meta: _91token_93S63u4dmQQAMeta || {},
    alias: _91token_93S63u4dmQQAMeta?.alias || [],
    redirect: _91token_93S63u4dmQQAMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/verifyEmail/[token].vue").then(m => m.default || m)
  }
]