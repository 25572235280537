import revive_payload_client_4MeqqfozJb from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.8.2_eslint@8.56.0_typescript@5.3.2_vite@5.0.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6tBLMPYbsm from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.8.2_eslint@8.56.0_typescript@5.3.2_vite@5.0.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7g0TsmqLzs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.8.2_eslint@8.56.0_typescript@5.3.2_vite@5.0.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5YFS7RoTUd from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.8.2_eslint@8.56.0_typescript@5.3.2_vite@5.0.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_4tr23qqFQl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.8.2_eslint@8.56.0_typescript@5.3.2_vite@5.0.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import colors_LZaKSEg17D from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.11.1_nuxt@3.8.2_vite@5.0.10_vue@3.3.8/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_3dFO4wL2SD from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import check_outdated_build_client_mE37qxYx5l from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.8.2_eslint@8.56.0_typescript@5.3.2_vite@5.0.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import i18n_VfGcjrvSkj from "/opt/buildhome/repo/plugins/i18n.ts";
export default [
  revive_payload_client_4MeqqfozJb,
  unhead_6tBLMPYbsm,
  router_7g0TsmqLzs,
  payload_client_5YFS7RoTUd,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4tr23qqFQl,
  colors_LZaKSEg17D,
  plugin_client_3dFO4wL2SD,
  check_outdated_build_client_mE37qxYx5l,
  i18n_VfGcjrvSkj
]