<script setup lang="ts">
useHead({
  link: [{ rel: 'stylesheet', href: 'https://rsms.me/inter/inter.css' }],
  htmlAttrs: {
    class: 'h-full bg-white',
  },
  bodyAttrs: {
    class: 'h-full',
  },
})
</script>

<template>
  <NuxtPage />
</template>
